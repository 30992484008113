// import ERC721 from '../../src/Abi/erc721.json'
// import ERC1155 from '../../src/Abi/erc1155.json'
// import TRADE from '../../src/Abi/market.json'
var EnvName = 'demo';
var key = {};
key.KEY = 'CardBo@rD1290%6Fine3'
key.ONEDAYINSECONDS = 0
key.BLOCKS_PER_YEAR = 0

key.RPAD_ADDRESS = ''
key.ROUTER = ''
key.EMAIL           =   /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
key.MOBILE          =   /^\d{10}$/
key.NumOnly         =   /^\d+$/
key.PASSWORD        =   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
key.OnlyAlbhabets   =   /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/
key.notSpecil       =   /^[a-zA-Z-9]+$/
key.OnlyAlphSpecial =   /^[A-Za-z_@.#&+-]*$/
key.IPFS            =   'https://ipfs.io/ipfs/'
key.limit = 50
key.NumDigitOnly = /[^0-9\.]/g
key.NumberOnly = /[^0-9]/g
if(EnvName === "demo") {
    key.FRONT_URL       =   'https://speciexdemo.maticz.in'
    key.FRONT_URL       =   'https://speciexdemo.maticz.in'
    key.BACK_URL        =   'https://speciexdemoapi.maticz.in/v1/front'
    key.ADMIN_URL        =   'https://speciexdemoapi.maticz.in/v1/admin'
    key.IMG_URL         =   'https://speciexdemoapi.maticz.in'
    key.DefaultImg       = 'https://speciexdemoapi.maticz.in/default.jpg'
    key.CoverImg        =  'https://speciexdemoapi.maticz.in/demobanner.jpg'
    key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.TradeContract   =   '0x3bE52bd3A97ac6Ba20F1482e58d9694B3E15Fb05'.toLowerCase()
    key.ERC721          =   '0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1'.toLowerCase()
    key.ERC1155         =    '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase()
    key.erc20Address    =   '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    key.RPC_URL         =   "https://api.avax-test.network/ext/bc/C/rpc"
    key.CHAIN_ID        =   43113
    key.Block_URL        =   "https://testnet.bscscan.com/"
    // key.RPC_URL         =   "https://endpoints.omniatech.io/v1/bsc/testnet/public"
    // key.CHAIN_ID        =   97
    // key.RPC_URL         =   "https://data-seed-prebsc-1-s2.binance.org:8545"
    // key.CHAIN_ID        =   97
    key.COIN_NAME        =   "BNB"
}
else if(EnvName === "stage") {
    
}
else if(EnvName === "production") {
    key.FRONT_URL       =   'https://nft.speciex.io/'
    key.BACK_URL        =   'https://backend.speciex.io/v1/front'
    key.ADMIN_URL        =   'https://backend.speciex.io/v1/admin'
    key.IMG_URL         =   'https://backend.speciex.io'
    key.DefaultImg       = 'https://backend.speciex.io/default.jpg'
    key.CoverImg        =  'https://backend.speciex.io/demobanner.jpg'
    key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.TradeContract   =   '0xefc4b417792f28f6808252172e17c35fbef28593'.toLowerCase()
    key.ERC721          =   '0xef5fbcb18cd95a8333cac5fc6de04279146d0548'.toLowerCase()
    key.ERC1155         =    '0x4602128e730e01f8e7425a871dda4adc66dbff02'.toLowerCase()
    key.erc20Address    =   '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'.toLowerCase()
    key.RPC_URL         =   "https://bsc-dataseed1.binance.org/"
    key.CHAIN_ID        =   56
    key.COIN_NAME        =   "BNB"
    key.Block_URL        =   "https://bscscan.com/"
}
else if(EnvName === "demossl"){
    key.FRONT_URL       =   'https://speciexdemo.maticz.in'
    key.BACK_URL        =   'https://speciexdemoapi.maticz.in/api/v1/front'
    key.ADMIN_URL        =   'https://speciexdemoadmin.maticz.in/api/v1/admin'
    key.IMG_URL         =   'https://speciexdemoapi.maticz.in'
    key.DefaultImg       = 'https://nftdemo.maticz.com/default.jpg'
    key.CoverImg        =  'https://nftdemo.maticz.com/demobanner.jpg'
    key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.TradeContract   =   '0x71CD3e9f29bDA1475138A2D3e1cCd6916Ab9f4Bf'.toLowerCase()
    key.ERC721          =   '0x2616Cb548b40e076b72Ab24C21Bfc3356eE87383'.toLowerCase()
    key.ERC1155         =    '0x484496568071F25678C07B372eBd6Ac54Bc18aB2'.toLowerCase()
    key.erc20Address    =   '0x7CAB80ce0E55F46378E493B584eE61aD68878f11'.toLowerCase()
    key.RPC_URL         =   "https://data-seed-prebsc-1-s2.binance.org:8545"
    key.CHAIN_ID        =   97
    // key.RPC_URL         =   "https://data-seed-prebsc-1-s2.binance.org:8545"
    // key.CHAIN_ID        =   97
    key.COIN_NAME        =   "BNB"
}
else{
    key.FRONT_URL       =   'http://localhost:3000/Ditmax'
    key.BACK_URL        =   'http://localhost:3331/v1/front'
    key.ADMIN_URL        =   'http://localhost:3331/v1/admin'
    key.IMG_URL         =   'http://localhost:3331'
    key.DefaultImg       = 'http://localhost:3331/default.jpg'
    key.CoverImg        =  'http://localhost:3331/demobanner.jpg'
    key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.TradeContract   =   '0x3bE52bd3A97ac6Ba20F1482e58d9694B3E15Fb05'.toLowerCase()
    key.ERC721          =   '0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1'.toLowerCase()
    key.ERC1155         =    '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase()
    key.erc20Address    =   '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    key.RPC_URL         =   "https://api.avax-test.network/ext/bc/C/rpc"
    key.CHAIN_ID        =   43113
    // key.RPC_URL         =   "https://endpoints.omniatech.io/v1/bsc/testnet/public"
    // key.CHAIN_ID        =   97
    // key.TradeContract   =   '0x71CD3e9f29bDA1475138A2D3e1cCd6916Ab9f4Bf'.toLowerCase()
    // key.ERC721          =   '0x2616Cb548b40e076b72Ab24C21Bfc3356eE87383'.toLowerCase()
    // key.ERC1155         =    '0x484496568071F25678C07B372eBd6Ac54Bc18aB2'.toLowerCase()
    // key.erc20Address    =   '0x7CAB80ce0E55F46378E493B584eE61aD68878f11'.toLowerCase()
    // // key.RPC_URL         =   "https://api.avax-test.network/ext/bc/C/rpc"
    // // key.CHAIN_ID        =   43113
    // key.RPC_URL         =   "https://data-seed-prebsc-1-s2.binance.org:8545"
    // key.CHAIN_ID        =   97
    key.Block_URL        =   "https://testnet.bscscan.com/"
    key.COIN_NAME        =   "BNB"
}
export default key;
