import React, { useState, useEffect } from "react";
import { Sociallinks, Newsletter } from "../actions/axioss/user.axios";
import config from "../views/config/config"
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { isEmpty } from "../actions/common";
import { toast } from "react-toastify";
import { getcms } from '../actions/axioss/user.axios';
import SmartPdf from '../assets/pdf/smart-contract-audit.pdf'
import whitspace from '../assets/pdf/whitespace.pdf'
import PrivacyPdf from '../assets/pdf/privacy-policy.pdf'
import SecurityPdf from '../assets/pdf/security-policy.pdf'
import cookiePdf from '../assets/pdf/cookie-policy.pdf'
import TermsPdf from '../assets/pdf/terms-and-conditions.pdf'
import DisclaimerPdf from '../assets/pdf/disclaimer.pdf'
export default function Footer(props) {
    var [email, setEmail] = useState('');
    var [Error, SetError] = useState({});
    // const navigate  = useNavigate();

    // const [click, setClick] = useState(false);
    // const gotoLive = () =>    
    // {
    //     setClick(true);
    //     setTimeout(()  =>
    //     {
    //         navigate("/");
    //         if((!isEmpty(props)))
    //         {
    //             console.log(click,"click")
    //             props?.func("liveauction",true)


    //     },1000);

    // }


    // if((!isEmpty(props)))
    // {
    //     console.log(click,"click")
    //     props?.func("",false)
    // }


    // props? props?.func("#liveaution") : null;
    // console.log(props,"props");


    // const gotoHome = (val) =>
    // {
    //     alert(1)

    //     navigate("/");

    // }
    const scrollTo = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }
    var [link, setLink] = useState([]);
    var [Error, SetError] = useState({});
    const { payload } = useSelector(state => state.LoginReducer.User)
    const [rest, setrest] = useState([])


    const push = useNavigate()
    useEffect(() => {
        var a = Getlink();
        getcmslistdata()
        // console.log("hello",a)
    }, [])

    const Getlink = async () => {

        let link_res = await Sociallinks();
        console.log("social lingk", link_res?.msg);
        setLink(link_res?.msg ?? [])
    }
    const getcmslistdata = async () => {
        console.log("sadsada");
        let Resp = await getcms();
        console.log("woccccccrd", Resp);
        setrest(Resp)
    }
    const NewsLetter = async () => {
        const id = toast.loading("Subscribing...");

        let err = {};
        if (email == '') err.email = "Email Id Required";
        if (email && !(config.EMAIL).test(email)) err.email = 'Invalid Email ID Format';
        SetError(err);
        // console.log('fhbdfhbdf',email)
        if (isEmpty(err)) {
            let resp = await Newsletter({ email: email });
            if (resp.success === 'success') {
                toast.update(id, { render: "Successfully Subscribed for NewsLetter", type: "success", isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
                setEmail('');
            }
            else {
                toast.update(id, { render: "Already User", type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
                SetError({ email: resp.msg });
            }
        }
        else {
            toast.update(id, { render: err.email, type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
        }
    }

    return (
        <>
            <footer className="footer">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 ">
                            <div className="widget widget-infor">
                                <div className="logo">
                                    <NavLink to="/"><p id="logo_header"></p></NavLink>
                                </div>

                                {rest?.map((item, idx) => {
                                    // { console.log("fkjkg", item.answer.length) }

                                    return (

                                        <p className="content">{item?.question == "Footer" ?
                                            <div
                                                dangerouslySetInnerHTML={{ __html: item?.answer?.length > 200 ? item?.answer.slice(0, 200) + '...' : item?.answer }}
                                            />

                                            : <></>}</p>);
                                })}

                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-4">
                            <div className="widget widget-menu">
                                <div className="menu menu-1">
                                    <h6 className="widget-title">Marketplace</h6>
                                    <ul >
                                        <li><Link to="/explore/All">All NFTs</Link></li>
                                        <li><Link to="/explore/ART">Art</Link></li>
                                        <li><Link to="/explore/Games">Games</Link></li>
                                        <li><Link to="/explore/Tickets">Photography</Link></li>
                                        <li><Link to="/explore/Audio">Music</Link></li>
                                        {/* <li><Link to="/collectionlist">Collection</Link></li> */}

                                        {/* <li><Link to="#" onClick={()=>{push ('/')
document.getElementById('liveauction').scrollTo({behaviour:"smooth"})
}}>Live Auction</Link></li> */}
                                        {/* <li><Link to="/"
                                >Live Auction</Link></li> */}

                                    </ul>
                                </div>

                                <div className="menu menu-3">
                                    <h6 className="widget-title">Resources</h6>
                                    <ul>
                                        {/* <li><Link to="/privacypolicy">Privacy Policy</Link></li> */}
                                        <li><Link to="/aboutus">About Us</Link></li>
                                        <li>
                                            <a href={SmartPdf} target="_blank"
                                                rel="noreferrer">
                                                Audit Report
                                            </a>
                                        </li>
                                        <li><a href={whitspace} target="_blank"
                                                rel="noreferrer">Whitepaper</a></li>
                                        <li><Link to="/faq">FAQ</Link></li>
                                        <li>
                                            <a href={"https://medium.com/@speciex_"} target="_blank"
                                                rel="noreferrer">
                                                Blog
                                            </a>
                                        </li>
                                        <li><Link to="#">Learn</Link></li>
                                    </ul>
                                </div>

                                <div className="menu menu-4">
                                    <h6 className="widget-title">My account</h6>
                                    <ul >
                                        <li><Link to={payload?.CustomUrl ? `/profile/${payload?.CustomUrl}` : "/connect"}>Profile</Link></li>
                                        <li><Link to={"/create"} state="owned">Create NFT</Link></li>
                                    </ul>
                                </div>

                                <div className="menu menu-2">
                                    <h6 className="widget-title">Legal</h6>
                                    <ul >
                                        {/* <li><Link to="#">Ranking</Link></li> */}
                                        <li>
                                            <a href={PrivacyPdf} target="_blank"
                                                rel="noreferrer">
                                                Privacy Policy
                                            </a>
                                        </li>
                                        <li>
                                            <a href={SecurityPdf} target="_blank"
                                                rel="noreferrer">
                                                Security Policy
                                            </a>
                                        </li>
                                        <li> <a href={DisclaimerPdf} target="_blank"
                                                rel="noreferrer">
                                                Disclaimer
                                            </a></li>
                                        <li>
                                            <a href={TermsPdf} target="_blank"
                                                rel="noreferrer">
                                                T&C
                                            </a>
                                        </li>
                                        <li>
                                            <a href={cookiePdf} target="_blank"
                                                rel="noreferrer">
                                                Cookie Policy
                                            </a>
                                        </li>
                                    </ul>
                                </div>


                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 ">
                            <div className="widget widget-subcribe">
                                <h6 className="widget-title">Subscribe Us</h6>
                                <p className="content">Get exclusive promotions & updates straight to your inbox.</p>
                                <form id="subscribe-form">
                                    <input type="email" placeholder="Info@yourgmail.com" value={'' || email} onChange={(event) => { setEmail(event.target.value) }} required="" id="subscribe-email" autoComplete="off" />
                                    <button className="tf-button" type="button" id="subscribe-button" onClick={NewsLetter}>Subscribe</button>
                                </form>
                                <div>
                                    <p className="my-3">Join our community</p>
                                    <ul className="social-item">
                                        {link.map((mlink) => (

                                            <>
                                                {/* {console.log('linksssss',mlink)} */}

                                                <li>
                                                    {
                                                        (mlink.website).toLowerCase() === "twitter" &&
                                                        <a href={mlink.link} target="blank"><i className="fab fa-twitter"></i></a>}
                                                </li>
                                                <li>
                                                    {(mlink.website).toLowerCase() === "facebook" &&
                                                        <a href={mlink.link} target="blank"><i className="fab fa-facebook"></i></a>}
                                                </li>
                                                <li>
                                                    {(mlink.website).toLowerCase() === "telegram" &&
                                                        <a href={mlink.link} target="blank"><i className="fab fa-telegram-plane"></i></a>}
                                                </li>
                                                <li>
                                                    {(mlink.website).toLowerCase() === "instagram" &&
                                                        <a href={mlink.link} target="blank"><i className="fab fa-instagram"></i></a>}
                                                </li>
                                                <li>
                                                    {(mlink.website).toLowerCase() === "medium" &&
                                                        <a href={mlink.link} target="blank"><i className="fab fa-medium"></i></a>}
                                                </li>
                                                <li>
                                                    {(mlink.website).toLowerCase() === "tik-tok-2" &&
                                                        <a href={mlink.link} target="blank"><i className="icon-fl-tik-tok-2"></i></a>}
                                                </li> 
                                                <li>
                                                    {(mlink.website).toLowerCase() === "vt" &&
                                                        <a href={mlink.link} target="blank"> <i className="icon-fl-vt"></i></a>}
                                                </li>
                                            </>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <a href="https://bscscan.com/address/0xF1B6460e7fA76E7aFDDfe20740C260b0Ec6807a8" target="_blank">
                        <p className="copy-right my-4">SPECIEX © 2023 | Smart Contract Address: 0xF1B6460e7fA76E7aFDDfe20740C260b0Ec6807a8</p>
                    </a>
                </div>
                <Link to="#" id="scroll-top" className="scrolltop_zindex" onClick={scrollTo}></Link>
            </footer>
        </>
    )
}